'use strict';

var $ = require('jquery');

var design_build = {
  init: function(){
  	this.db_transition.actions();
  },

  ready: function(){
  },
  
  resize:function(){
  },
  
  scroll: function(){
    
  },
  db_transition: {
  	actions: function(){
  		var db = this;
	  	$('.design-callout').click(function(){
	  		db.switchtodesign();
	  	});
	  	$('.build-callout').click(function(){
				db.switchtobuild();
	  	});
	  	$('.design-callout-ie-ff').click(function(){
				db.switchtodesign();
	  	});
	  	$('.build-callout-ie-ff').click(function(){
	  		db.switchtobuild();
	  	});
	  },
	  switchtobuild: function(){
			var design = $('.design-area');
	  	if(!design.hasClass('animating')){
	  		if(!design.hasClass('inactive')){
					design.addClass('inactive').addClass('animating').removeClass('active');
					$('.design-callout-ie-ff').addClass('active');
					$('.build-callout-ie-ff').removeClass('active');
					$('.design-build-area').addClass('build-active');
					$('.design-build-area').removeClass('design-active');
					setTimeout(function(){
						design.removeClass('animating');
					},1000);
	  		}
	  	}
	  },
	  switchtodesign: function(){
  		var design = $('.design-area');
  		if(!design.hasClass('animating')){
	  		if(design.hasClass('inactive')){
					design.addClass('active').addClass('animating').removeClass('inactive');
					$('.design-callout-ie-ff').removeClass('active');
					$('.build-callout-ie-ff').addClass('active');
					
					$('.design-build-area').addClass('design-active');
					$('.design-build-area').removeClass('build-active');
					setTimeout(function(){
						design.removeClass('animating');
					},1000);
				}
	  	}
	  }
  }

};
module.exports = design_build;
