var claris = {
  modules : [],
  init : function(){
    this.modules.forEach(function(item, i){
      if(item.hasOwnProperty('init')){
        item.init(item);
      }
    });
  },
  ready : function(){
    this.modules.forEach(function(item, i){
      if(item.hasOwnProperty('ready')){
        item.ready(item);
      }
    });
  },
  scroll : function(){
    var self = this;
      self.modules.forEach(function(item, i){
        if(item.hasOwnProperty('scroll')){
          item.scroll(item);
        }
      });
  },
  resize : function(){
    this.modules.forEach(function(item, i){
      if(item.hasOwnProperty('resize')){
        item.resize(item);
      }
    });
  }
}

claris.modules.push(require('./global'));
claris.modules.push(require('./fajax'));

if (document.querySelectorAll('.home').length){
  claris.modules.push(require('./home'));
}
if (document.querySelectorAll('.portfolio').length){
  claris.modules.push(require('./portfolio'));
}
if (document.querySelectorAll('.testimonials').length){
  claris.modules.push(require('./testimonials'));
}
if (document.querySelectorAll('.design-build').length){
  claris.modules.push(require('./design-build'));
}
claris.init(claris);
document.addEventListener('DOMContentLoaded', claris.ready.bind(claris));
document.addEventListener('scroll', claris.scroll.bind(claris));
window.addEventListener('resize', claris.resize.bind(claris));