'use strict';

var $ = require('jquery');
var slick = require('slick-carousel');
var youtubePlayer = require('youtube-player');

var home = {
  init: function(){
    this.portfolio_slideshow();
  },

  ready: function(){
    this.design_build();
    if ($(window).width() > 768){
      this.opening_video.init();
    }
  },
  
  resize:function(){
  },
  
  scroll: function(){
    
  },
  opening_video: {
    init: function(){
      var self = this;
      var player = youtubePlayer('video-player', 
        {
          playerVars: {"rel": 0, "volume": 0, "controls": 0,"modestbranding": 0,"showinfo": 0},
          controls: false
      });
      player.mute();
      player.loadVideoById($('#video-player').attr('data-video-id'));
      player.on('stateChange', function (event) {
        if (event.data == 0) {
          player.playVideo();          
        }
      });

    },
  },
  portfolio_slideshow: function(){
    $('.portfolio-slides').slick({
      prevArrow: '<div class="slick-arrow icon-angle-left"></div>',
      nextArrow: '<div class="slick-arrow icon-angle-right"></div>',
      autoplay: true,
      autoplaySpeed: 4000,
      rows:0
    });
  },
  design_build: function(){
    $('.build-text').mouseenter(function(){
      $(this).parent().parent().find('.design').addClass('shorter');
      $(this).parent().parent().find('.design').removeClass('longer');      
    });
      
    $('.design-text').mouseenter(function(){
      $(this).parent().parent().find('.design').removeClass('shorter');
      $(this).parent().parent().find('.design').addClass('longer');
    });
    $('.design-build-text').mouseleave(function(){
      $(this).parent().find('.design').removeClass('longer').removeClass('shorter');
    })
  }
};
module.exports = home;
