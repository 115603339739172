'use strict';

var $ = require('jquery');
var slick = require('slick-carousel');

var portfolio = {
  init: function(){
    this.slideshow();
  },

  ready: function(){
    this.sizeDescription();
  },
  
  resize:function(){
    this.sizeDescription();
  },
  
  scroll: function(){
    
  },
  sizeDescription: function(){
    if ($(window).width() >= 1050){
      var imageHeight = $('.portfolio-images').height();
      $('.portfolio-details').css({"min-height":imageHeight});
    }
  },
  slideshow: function(){
    var  portfolio_images = $('.portfolio-images .portfolio-image img').clone();
    $('.portfolio-thumbs').append(portfolio_images);
    // $('.portfolio-thumbs .thumbs img:first-child').addClass('active');
    $('.portfolio-images').slick({
      prevArrow: '<div class="slick-arrow icon-angle-left"></div>',
      nextArrow: '<div class="slick-arrow icon-angle-right"></div>',
      arrows: true
    });
    $('.portfolio-thumbs').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.portfolio-images',
      dots: false,
      arrows: true,
      centerMode: true,
      focusOnSelect: true,
      prevArrow: '<div class="prev"><span class="slick-arrow icon-angle-left"></span></div>',
      nextArrow: '<div class="next"><span class="slick-arrow icon-angle-right"></span></div>',
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow:2,
            slidesToScroll: 1
          }
        }
      ]
    });
      
  }
};
module.exports = portfolio;
