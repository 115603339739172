'use strict';

var $ = require('jquery');

var global = {
  init: function(){
    
  },

  ready: function(){
    if ($('.nav-icon').length){
      this.nav.init();
    }
    this.scrolltoLinks();
    $(window).bind("pageshow", function(event) {
      if (event.originalEvent.persisted) {
          window.location.reload() 
      }
    });
        if ($(".newsletter-form").length){
      $('.newsletter-form').submit(this.mailchimpSignup.subscribe.bind(this.mailchimpSignup));      
    }
  },
   mailchimpSignup :{
      form : $('.newsletter-form'),
      subscribe : function(e){
        e.preventDefault();
        var form = this.form,
          td = $('.newsletter-form').attr('data-td'),
          link = td + '/assets/php/mc_subscribe.php',
          request = $.ajax({
                      url: link,
                      type: 'POST',
                      data : $('.newsletter-form').serialize()
                    });
        request.done(this.handleResponse.bind(this));
      },
      handleResponse : function(response){
      function outputMessage(msg){
        $('.newsletter-form').find('input[type=text]').val('');
        $('.newsletter-form').find('input[type=text]').blur();
        $('.newsletter-form').find('input[type=text]').attr('placeholder',msg);
      };
      var form = this.form;

      var resp = JSON.parse(response);          
      if (resp.status == 'subscribed')
      {
        outputMessage('Thank you!.');
      } else if (resp.title == 'Invalid Resource' || resp.title == 'Internal Server Error') {
        outputMessage('Please enter a valid email address.');
      } else if (resp.title == 'Member Exists') {
        outputMessage('You are already subscribed.');
      }
    }
  },
  scrolltoLinks : function(){
    var hashLinks = $("a[href^='#']");
    hashLinks.click(function(e){
      var link = $(this);
      e.preventDefault();
      $('body').animate({scrollTop:$(link.attr('href')).position().top - 50}, '500');
    })
  },
  
  resize:function(){
    
  },
  
  scroll: function(){
    if ($(window).scrollTop()>0){
      $('header').addClass('scrolled');
    }else{
      $('header').removeClass('scrolled');
    }
  },
  nav: {
    init: function(){      
      $('.nav-icon').click(function() {

        if ($('body').hasClass('nav-open')){
          $('body').toggleClass('nav-open');
        }else{
          $('body').toggleClass('nav-open');
        }
      });
    }
  }

};
module.exports = global;