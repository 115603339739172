'use strict';

var $ = require('jquery');
var slick = require('slick-carousel');

var testimonials = {
  init: function(){
    this.testimonial_slideshow();
  },

  ready: function(){
  },
  
  resize:function(){
  },
  
  scroll: function(){
    
  },
  testimonial_slideshow: function(){
    $('.testimonials-quotes').slick({
      prevArrow: '<div class="slick-arrow icon-angle-left"></div>',
      nextArrow: '<div class="slick-arrow icon-angle-right"></div>',
    });
  }
};
module.exports = testimonials;
